import axios from 'axios';

const API_KEY = 'AIzaSyD-Es2ZIXivmsyb9MIwJKIrgZNmsMfi7q4';
const PLACE_ID = 'ChIJIevOrNRRZCMRRo7R9gPR95E';

export async function fetchReviews() {
  const proxyurl = 'https://corsproxy.io/?';
  const url =
    proxyurl +
    `https://maps.googleapis.com/maps/api/place/details/json?place_id=${PLACE_ID}&fields=reviews&key=${API_KEY}`;

  try {
    const response = await axios.get(url);
    if (response.data.result && response.data.result.reviews) {
      return response.data.result.reviews;
    } else {
      console.error('No reviews found in the response');
      return [];
    }
  } catch (error) {
    console.error('Error fetching reviews:', error);
    return [];
  }
}
