import { defaultStyles } from '@/utils/defaultStyles';
import Carousel from 'react-multi-carousel';
import { CustomLeftArrow, CustomRightArrow } from './CustomCarouselArrows';
import { fetchReviews } from '@/utils/fetchReviews';
import { useEffect, useState } from 'react';
import Stars from './Stars';

function Slide(props: {
  author_name: string;
  rating: number;
  relative_time_description: string;
  text: string;
}) {
  return (
    <div className="flex flex-col gap-4 border border-[#CACACA] border-solid m-1 p-4 rounded-[26px]">
      <div className="text-black font-bold text-[25px]">
        {props.author_name}
      </div>
      <div className="flex flex-row gap-2 items-center">
        <Stars actualRating={props.rating} />
        <div className="text-[15px] text-black">
          {props.relative_time_description}
        </div>
      </div>
      <div className="text-[16px]">{props.text}</div>
      <img width={32} height={32} src="/google-rev.png" />
    </div>
  );
}

export default function ReviewsSection() {
  const [reviews, setReviews] = useState<any[]>([]);

  useEffect(() => {
    async function loadReviews() {
      const fetchedReviews = await fetchReviews();
      setReviews(fetchedReviews);
    }

    loadReviews();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1400, min: 800 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 800, min: 0 },
      items: 1,
    },
  };

  return (
    <section
      className={`flex flex-col gap-8 py-12 md:py-24 ${defaultStyles.paddingBig}`}
    >
      <div className="text-[35px] xl:text-[45px] text-black">
        Sprawdź <span className="font-bold">co sądzą</span>
        <br /> o nas <span className="font-bold">nasi klienci</span>
      </div>
      <Carousel
        responsive={responsive}
        slidesToSlide={1}
        autoPlay
        autoPlaySpeed={4000}
        infinite
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        className="pb-12"
      >
        {reviews.map((item, index) => (
          <Slide {...item} key={index} />
        ))}
      </Carousel>
    </section>
  );
}
