import { defaultStyles } from '@/utils/defaultStyles';

export default function MainPageBannerSection() {
  return (
    <section
      className={`flex flex-col xl:flex-row gap-8 items-center justify-between py-12 md:py-24 ${defaultStyles.paddingBig}`}
    >
      <div className="flex flex-col gap-8 xl:w-[40%]">
        <div className="text-[35px] xl:text-[45px]">
          Parę słów
          <br />
          <span className="font-bold">o SellWell Nieruchomości</span>
        </div>
        <div className="text-[16px] font-bold text-[#32A771]">
          Wspieramy w całym procesie wynajmu, sprzedaży oraz kupna
          nieruchomości.
        </div>
        <div className="text-[16px] text-[#626262]">
          Naszym celem jest odciążenie właścicieli od codziennych obowiązków
          związanych z najmem. Dzięki szerokiej sieci kontaktów oraz skutecznym
          strategiom marketingowym szybko znajdujemy solidnych najemców.
          Minimalizujemy ryzyko prawne we współpracy z doświadczonymi
          prawnikami, monitorujemy płatności czynszu, organizujemy naprawy i
          rozwiązujemy problemy. Działamy w województwie łódzkim oraz
          ogólnokrajowo, zajmując się sprzedażą, kupnem, wynajmem i inwestycjami
          w nieruchomości. Oferujemy kompleksowe wsparcie na każdym etapie
          transakcji, opierając się na naszej rozległej sieci kontaktów i
          dogłębnej wiedzy lokalnego rynku. Pomagamy kupującym znaleźć idealne
          nieruchomości, zapewniając pełne wsparcie prawne i techniczne, a
          właścicielom oferujemy profesjonalne zarządzanie oraz doradztwo
          inwestycyjne.
          <br />
          <br />Z naszym wieloletnim doświadczeniem na rynku osiągniesz sukces w
          nieruchomościach.
        </div>
      </div>
      <img
        alt="banner"
        loading="lazy"
        className="w-[70%] md:w-[50%] max-w-[651px] max-h-[672px]"
        src="/main-page-banner.png"
      />
    </section>
  );
}
