import { defaultStyles } from '@/utils/defaultStyles';
import Carousel from 'react-multi-carousel';
import { CustomLeftArrow, CustomRightArrow } from './CustomCarouselArrows';

const items = [
  {
    title: 'Sprzedaż',
    desc: 'Sprzedajemy nieruchomości w województwie Łódzkim.',
    image: '/offer-1.png',
    route: '/sales',
    svg: (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_430_78)">
          <path
            d="M19 0C8.52308 0 0 8.52308 0 19C0.95475 44.1718 37.05 44.1655 38 19C38 8.52308 29.4769 0 19 0ZM20.5833 26.9167V28.5C20.5754 30.571 17.4246 30.5694 17.4167 28.5V26.9167H16.9923C15.3029 26.9167 13.7259 26.0078 12.8788 24.5433C12.4403 23.7848 12.6999 22.8174 13.4552 22.3804C14.2136 21.9387 15.1826 22.2015 15.618 22.9568C15.9014 23.4476 16.4271 23.75 16.9908 23.75H20.5817C22.1603 23.8529 22.8665 21.2958 21.1454 20.9633L16.3305 20.1606C10.7857 19.133 11.9795 11.0517 17.4151 11.0833V9.5C17.4246 7.429 20.5738 7.43058 20.5817 9.5V11.0833H21.0061C22.6955 11.0833 24.2725 11.9938 25.1196 13.4583C25.5582 14.2152 25.2985 15.1826 24.5433 15.6212C23.7833 16.0598 22.8158 15.8001 22.3804 15.0433C22.097 14.554 21.5713 14.2516 21.0077 14.2516H17.4167C15.8381 14.1487 15.1335 16.7057 16.853 17.0382L21.6679 17.841C27.2128 18.8686 26.0189 26.9483 20.5833 26.9167Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_430_78">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'Kupno',
    desc: 'Zajmujemy się zakupem różnorodnych nieruchomości na terenie całego kraju.',
    image: '/offer-2.png',
    route: '/buy',
    svg: (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_430_80)">
          <path
            d="M28.5 19.0001C23.2528 19.0001 19 23.2529 19 28.5001C19 33.7472 23.2528 38.0001 28.5 38.0001C33.7472 38.0001 38 33.7472 38 28.5001C38 23.2529 33.7472 19.0001 28.5 19.0001ZM34.3314 28.0441L30.0517 32.1829C29.336 32.8891 28.386 33.2453 27.4344 33.2453C26.4828 33.2453 25.5297 32.8891 24.8013 32.1781L22.6575 30.0691C22.0321 29.4564 22.0242 28.4541 22.6353 27.8303C23.2465 27.2049 24.2488 27.1954 24.8742 27.8081L27.018 29.9156C27.246 30.1404 27.6197 30.1356 27.8445 29.9124L32.1401 25.7577C32.7687 25.1545 33.7725 25.1719 34.3789 25.8036C34.9837 26.4338 34.9632 27.4361 34.333 28.0425L34.3314 28.0441ZM20.1574 38.0064H7.91667C3.55142 38.0064 0 34.455 0 30.0897V15.4027C0 12.7665 1.30467 10.3139 3.48808 8.83981L14.5714 1.36014C17.2615 -0.454357 20.7385 -0.454357 23.4286 1.36014L34.8333 9.08681C36.8157 10.5783 38 12.9026 38 15.4027V20.1496C35.6772 17.5101 32.2842 15.8334 28.5 15.8334C21.5159 15.8334 15.8333 21.516 15.8333 28.5001C15.8333 32.2874 17.5133 35.6836 20.1574 38.0064Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_430_80">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'Wynajem',
    desc: 'Wynajmij nieruchomość w województwie Łódzkim.',
    image: '/offer-3.png',
    route: '/lease',
    svg: (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_430_82)">
          <path
            d="M25.3333 34.8332V37.9999H12.6667V34.8332C12.6667 31.3404 15.5072 28.4999 19 28.4999C22.4928 28.4999 25.3333 31.3404 25.3333 34.8332ZM19 12.6666C17.2536 12.6666 15.8333 14.0868 15.8333 15.8332C15.8333 17.5797 17.2536 18.9999 19 18.9999C20.7464 18.9999 22.1667 17.5797 22.1667 15.8332C22.1667 14.0868 20.7464 12.6666 19 12.6666ZM38 15.3345V30.0832C38 34.4485 34.4486 37.9999 30.0833 37.9999H28.5V34.8332C28.5 29.594 24.2393 25.3332 19 25.3332C13.7608 25.3332 9.5 29.594 9.5 34.8332V37.9999H7.91667C3.55142 37.9999 0 34.4485 0 30.0832V15.3345C0 12.6998 1.30308 10.2457 3.48808 8.76998L14.5714 1.29032C17.2615 -0.524182 20.7385 -0.524182 23.4286 1.29032L34.5119 8.76998C36.6969 10.2457 38 12.6982 38 15.3345ZM25.3333 15.8332C25.3333 12.3404 22.4928 9.4999 19 9.4999C15.5072 9.4999 12.6667 12.3404 12.6667 15.8332C12.6667 19.3261 15.5072 22.1666 19 22.1666C22.4928 22.1666 25.3333 19.3261 25.3333 15.8332Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_430_82">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: 'Inwestycje',
    desc: 'Kupno nieruchomości z SellWell.',
    image: '/offer-4.png',
    route: '/invest',
    svg: (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_430_84)">
          <path
            d="M35.6249 6.29541H30.7387C30.4256 6.29548 30.1195 6.38837 29.8592 6.56236C29.5989 6.73634 29.396 6.9836 29.2762 7.27287C29.1564 7.56215 29.125 7.88045 29.1861 8.18754C29.2472 8.49464 29.3979 8.77673 29.6193 8.99816L31.3388 10.7177L25.6609 16.3939C25.3593 16.6822 24.958 16.8431 24.5407 16.8431C24.1234 16.8431 23.7222 16.6822 23.4205 16.3939L23.1514 16.1232C22.2467 15.2588 21.0436 14.7764 19.7923 14.7764C18.5411 14.7764 17.338 15.2588 16.4333 16.1232L8.35827 24.1982C8.06075 24.4965 7.89394 24.9008 7.89453 25.3222C7.89513 25.7435 8.06308 26.1474 8.36143 26.4449C8.65979 26.7424 9.06412 26.9092 9.48546 26.9086C9.90681 26.9081 10.3107 26.7401 10.6082 26.4417L18.6832 18.3667C18.9847 18.0781 19.386 17.9169 19.8034 17.9169C20.2208 17.9169 20.6221 18.0781 20.9236 18.3667L21.1928 18.6375C22.0978 19.5012 23.3008 19.983 24.5518 19.983C25.8028 19.983 27.0058 19.5012 27.9109 18.6375L33.5887 12.9597L35.3082 14.6792C35.5304 14.8969 35.8118 15.0444 36.1173 15.1033C36.4228 15.1621 36.7389 15.1295 37.026 15.0098C37.3131 14.89 37.5586 14.6883 37.7318 14.4299C37.905 14.1714 37.9983 13.8677 37.9999 13.5566V8.67041C37.9999 8.04052 37.7496 7.43643 37.3042 6.99103C36.8588 6.54563 36.2547 6.29541 35.6249 6.29541Z"
            fill="white"
          />
          <path
            d="M36.4167 34.7953H7.91667C6.65689 34.7953 5.44871 34.2949 4.55791 33.4041C3.66711 32.5133 3.16667 31.3051 3.16667 30.0453V1.58333C3.16667 1.16341 2.99985 0.76068 2.70292 0.463748C2.40599 0.166815 2.00326 0 1.58333 0C1.16341 0 0.76068 0.166815 0.463748 0.463748C0.166815 0.76068 0 1.16341 0 1.58333L0 30.0453C0.00251411 32.1442 0.837396 34.1564 2.32151 35.6405C3.80563 37.1246 5.81781 37.9595 7.91667 37.962H36.4167C36.8366 37.962 37.2393 37.7952 37.5363 37.4983C37.8332 37.2013 38 36.7986 38 36.3787C38 35.9587 37.8332 35.556 37.5363 35.2591C37.2393 34.9621 36.8366 34.7953 36.4167 34.7953Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_430_84">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];

export default function OurOfferSection() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1800 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 700 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
    },
  };

  return (
    <section
      className={`flex flex-col gap-8 py-12 md:py-24 bg-[#F3F3F3] ${defaultStyles.paddingBig}`}
    >
      <div className="flex flex-col gap-4">
        <div className="text-[35px] xl:text-[45px] text-black">
          Sprawdź <br />
          <span className="font-bold">naszą ofertę</span>
        </div>
        <Carousel
          responsive={responsive}
          slidesToSlide={1}
          autoPlay
          autoPlaySpeed={4000}
          infinite
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
          className="pb-12"
        >
          {items.map((item, index) => (
            <div
              className="relative flex flex-col rounded-[20px] h-[460px] border border-[#CACACA] bg-white pb-4 border-solid mx-4"
              key={index}
            >
              <img
                alt={item.title}
                loading="lazy"
                className="h-[240px] min-h-[240px] md:h-[189px] md:min-h-[189px] rounded-t-[20px]"
                src={item.image}
              />
              <div className="absolute top-[12.8rem] md:top-[9.5rem] left-5 rounded-full w-[66px] h-[66px] bg-[#32A771] flex flex-col justify-center items-center">
                {item.svg}
              </div>
              <div className="flex flex-col gap-4 bg-white h-full p-4 pt-12 rounded-b-[20px]">
                <div className="text-[25px] text-black font-bold">
                  {item.title}
                </div>
                <div className="text-[16px] text-black">{item.desc}</div>
              </div>
              <button
                onClick={() => window.location.assign(item.route)}
                className="ml-4 cursor-pointer uppercase text-[16px] text-white font-semibold flex flex-row justify-between items-center bg-[#32A771] outline-none border-none rounded-[63px] py-2 px-4 max-w-[200px]"
              >
                <div>Więcej</div>
                <svg
                  width="39"
                  height="39"
                  viewBox="0 0 39 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="19.5" cy="19.5" r="19.5" fill="white" />
                  <path
                    d="M19.9216 16.1716C21.4837 17.7337 21.4837 20.2663 19.9216 21.8284L16.75 25C16.4739 25.2761 16.0261 25.2761 15.75 25C15.4739 24.7239 15.4739 24.2761 15.75 24L18 21.75C19.5188 20.2312 19.5188 17.7688 18 16.25L15.75 14C15.4739 13.7239 15.4739 13.2761 15.75 13C16.0261 12.7239 16.4739 12.7239 16.75 13L19.9216 16.1716Z"
                    fill="#32A771"
                  />
                </svg>
              </button>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
}
