import { defaultStyles } from '@/utils/defaultStyles';

export default function PolicySection() {
  return (
    <section
      className={`flex flex-col gap-4 py-12 md:py-24 ${defaultStyles.paddingBig}`}
    >
      <div className="font-bold text-[35px] xl:text-[45px] text-black">
        Polisa OC
      </div>
      <div className="text-[16px] text-[#626262]">1 000 000 zł</div>
      <img
        loading="lazy"
        className="self-center w-full rounded-[55px] max-w-[768px] max-h-[768px]"
        src="/policy.png"
        alt="Polisa"
      />
    </section>
  );
}
