import { defaultStyles } from '@/utils/defaultStyles';

const items = [
  {
    name: 'Aleksander Jałocha',
    desc: 'Prezes zarządu',
    image: '/employee-5.png',
    phone: '530 923 663',
    email: 'a.jalocha@sellwell-nieruchomosci.pl',
    longDesc:
      'Aleksander, prezes naszej firmy i aktywny pośrednik w obrocie nieruchomościami, wyróżnia się zaangażowaniem, profesjonalizmem i indywidualnym podejściem do klientów. Dzięki bogatemu doświadczeniu i pasji, zapewnia najwyższą jakość usług. Dąży do perfekcji, co gwarantuje sprawne i zaangażowane przeprowadzanie transakcji.',
  },
  {
    name: 'Amadeusz Koziński',
    desc: 'Doradca ds. Nieruchomości',
    image: '/employee.png',
    phone: '537 660 194',
    email: 'a.kozinski@sellwell-nieruchomosci.pl',
    longDesc:
      'Amadeusz, doradca ds. nieruchomości, znany jest z szczerości i zaangażowania. Jego bezpośredniość i otwartość budują zaufanie klientów. Pasja do pracy pozwala mu dostarczać usługi najwyższej jakości. Jest ceniony za prawdomówność i profesjonalizm.',
  },
  {
    name: 'Adam Cienkus',
    desc: 'Ekspert ds. Nieruchomości',
    image: '/employee-2.png',
    phone: '792 160 498',
    email: 'a.cienkus@sellwell-nieruchomosci.pl',
    longDesc:
      'Adam, ekspert ds. nieruchomości, zawsze uśmiechnięty, uwielbia pracę z ludźmi. Jego pogodne usposobienie, profesjonalizm i doświadczenie czynią go idealnym doradcą. Z pasją i entuzjazmem podchodzi do każdego zadania, zapewniając najwyższy standard obsługi.',
  },
  {
    name: 'Artur Martyka',
    desc: 'Doradca ds. Nieruchomości',
    image: '/employee-3.png',
    phone: '517 837 570',
    email: 'a.martyka@sellwell-nieruchomosci.pl',
    longDesc:
      'Artur, doradca ds. nieruchomości, wyróżnia się spokojem i profesjonalizmem. Jego przemyślane podejście zapewnia najwyższą jakość obsługi. Dzięki zaangażowaniu i doświadczeniu, każda transakcja jest przeprowadzana sprawnie i z pełnym zrozumieniem potrzeb klienta.',
  },
  {
    name: 'Sylwia Machałowska',
    desc: 'Specjalista ds. projektowania wnętrz',
    image: '/employee-4.png',
    phone: '795 574 401',
    email: 's.machalowska@sellwell-nieruchomosci.pl',
    longDesc:
      'Sylwia, specjalista ds. projektowania wnętrz z dyplomem ASP, tworzy wyjątkowe i harmonijne wnętrza. Jej profesjonalizm i artystyczne podejście gwarantują estetyczne i funkcjonalne przestrzenie. Sylwia zaraża swoją pozytywną energią i ciepłem.',
  },
  {
    name: 'Wojciech Rokicki',
    desc: 'Ekspert ds. Nieruchomości',
    image: '/employee-6.png',
    phone: '512 663 165',
    email: 'w.rokicki@sellwell-nieruchomosci.pl',
    longDesc:
      'Wojciech, ekspert ds. nieruchomości, znany z empatii i doświadczenia, zapewnia transakcje w atmosferze zrozumienia i zaufania. Profesjonalizm i ludzkie podejście sprawiają, że współpraca z nim jest owocna i przyjemna. Wojciech zawsze stawia dobro klienta na pierwszym miejscu.',
  },
];

function EmployeeItem(props: {
  name: string;
  desc: string;
  image: string;
  phone?: string;
  email?: string;
  longDesc: string;
}) {
  return (
    <div className="flex flex-col md:flex-row gap-4">
      <img
        width={168}
        height={168}
        alt={props.name}
        loading="lazy"
        src={props.image}
      />
      <div className="flex flex-col gap-2">
        <div
          className={`font-bold text-[18px] lg:text-[25px] transition-all duration-300`}
        >
          {props.name}
        </div>
        <div className={`text-[14px] transition-all duration-300 w-[80%]`}>
          {props.desc}
        </div>
        <div className="flex flex-col gap-2">
          {props.phone && (
            <div className="flex flex-row gap-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_566_4564)">
                  <path
                    d="M1.21413 9.56642C4.00717 15.6532 8.9771 20.4925 15.1559 23.116L16.1464 23.5576C18.4124 24.5679 21.0764 23.8004 22.4585 21.7391L23.7528 19.8087C24.1736 19.1811 24.0455 18.3357 23.4577 17.8612L19.068 14.3178C18.4232 13.7973 17.4752 13.9187 16.982 14.5845L15.6241 16.4181C12.1396 14.6981 9.31056 11.8676 7.59176 8.38105L9.4241 7.02232C10.0897 6.5288 10.2108 5.58015 9.69066 4.93503L6.14929 0.542722C5.67511 -0.0453809 4.83053 -0.173667 4.20329 0.247154L2.26075 1.55044C0.187766 2.94121 -0.575031 5.62864 0.457903 7.90205L1.21299 9.56396L1.21413 9.56642Z"
                    fill="#32A771"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_566_4564">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div className="text-black">{props.phone}</div>
            </div>
          )}
          {props.email && (
            <div className="flex flex-row gap-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_566_4569)">
                  <path
                    d="M12 15.6C10.0152 15.6 8.4 13.9848 8.4 12C8.4 10.0152 10.0152 8.4 12 8.4C13.9848 8.4 15.6 10.0152 15.6 12C15.6 13.9848 13.9848 15.6 12 15.6ZM12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C12.3182 24 12.6235 23.8735 12.8485 23.6485C13.0735 23.4235 13.2 23.1182 13.2 22.8C13.2 22.4818 13.0735 22.1765 12.8485 21.9515C12.6235 21.7265 12.3182 21.6 12 21.6C6.7068 21.6 2.4 17.2932 2.4 12C2.4 6.7068 6.7068 2.4 12 2.4C17.2932 2.4 21.6 6.7068 21.6 12V13.2C21.6 14.1924 20.7924 15 19.8 15C18.8076 15 18 14.1924 18 13.2V12C18 8.6916 15.3084 6 12 6C8.6916 6 6 8.6916 6 12C6 15.3084 8.6916 18 12 18C13.8372 18 15.4644 17.1516 16.566 15.846C16.9555 16.3292 17.448 16.7196 18.0074 16.9884C18.5669 17.2572 19.1792 17.3978 19.8 17.4C22.116 17.4 24 15.516 24 13.2V12C24 5.3832 18.6168 0 12 0Z"
                    fill="#32A771"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_566_4569">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <div className="text-black">{props.email}</div>
            </div>
          )}
        </div>
        <div className="text-[#626262] text-[14px]">{props.longDesc}</div>
      </div>
    </div>
  );
}

export default function OurTeamSection() {
  return (
    <section
      className={`flex flex-col gap-8 py-12 md:py-24 ${defaultStyles.paddingBig}`}
    >
      <div className="flex flex-col gap-8 2xl:w-[40%]">
        <div className="text-[35px] xl:text-[45px] text-black">
          Poznaj
          <br /> <span className="font-bold">nasz zespół</span>
        </div>
        <div className="text-[16px] text-[#626262] leading-[32.48px]">
          Nasz zespół składa się z profesjonalistów, którzy nie tylko posiadają
          szeroką wiedzę branżową, ale również angażują się w budowanie zaufania
          i długotrwałych relacji z naszymi klientami. Poznaj naszych ekspertów
        </div>
        <div className="text-[16px] text-[#626262] leading-[32.48px]">
          Współpracujemy z radcami prawnymi, notariuszami i doradcami
          kredytowymi. Jesteśmy gotowi podjąć każde wyzwanie i zapewnić Ci
          wsparcie, którego potrzebujesz, aby osiągnąć sukces na rynku
          nieruchomości.
        </div>
      </div>
      <div className="flex flex-row flex-wrap gap-8">
        {items.map((item, index) => (
          <EmployeeItem {...item} key={index} />
        ))}
      </div>
    </section>
  );
}
