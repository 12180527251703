import { defaultStyles } from '@/utils/defaultStyles';

const items = [
  {
    img: '/landing-coop-1.png',
    title: 'Skuteczność i profesjonalizm',
    svg: (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_566_5244)">
          <mask
            id="mask0_566_5244"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="38"
            height="38"
          >
            <path d="M0 0H38V38H0V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_566_5244)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.3496 0.442599C12.8449 3.06981 7.14524 4.86808 2.84372 5.46964C1.28235 5.68801 0.00277752 6.97139 0.00637909 8.54856L0.0085345 9.49244C-0.0492763 20.9733 0.915487 31.0228 17.9869 37.8087C18.6288 38.0636 19.3626 38.0638 20.0044 37.8089C37.0901 31.023 38.0599 20.9735 38.0021 9.49244L38.0016 8.54039C38.0006 6.96641 36.7223 5.67194 35.1681 5.42801C31.0867 4.78751 25.1331 3.008 20.6329 0.434482C19.6181 -0.145933 18.3595 -0.146415 17.3496 0.442599ZM26.0456 16.55C26.7878 15.8077 26.7878 14.6042 26.0456 13.8619C25.3037 13.1196 24.1006 13.1196 23.3586 13.8619L17.1022 20.1208L14.6457 17.6634C13.9037 16.9211 12.7007 16.9211 11.9587 17.6634C11.2167 18.4057 11.2167 19.6091 11.9587 20.3515L15.7587 24.153C16.5007 24.8953 17.7037 24.8953 18.4457 24.153L26.0456 16.55Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_566_5244">
            <rect width="38" height="38" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    img: '/landing-coop-2.png',
    title: 'Stały kontakt z agentem',
    svg: (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.78533 13.2109C4.92909 13.709 3.5625 15.4032 3.5625 17.4167V22.1667C3.5625 24.5714 5.51193 26.5208 7.91667 26.5208H11.875C12.5308 26.5208 13.0625 25.9892 13.0625 25.3333V14.25C13.0625 13.5942 12.5308 13.0625 11.875 13.0625H9.20681C9.88632 9.1517 13.8678 5.9375 19 5.9375C24.1322 5.9375 28.1137 9.1517 28.7932 13.0625H26.125C25.4692 13.0625 24.9375 13.5942 24.9375 14.25V25.3333C24.9375 25.9892 25.4692 26.5208 26.125 26.5208H28.7581C28.222 28.7904 26.1833 30.4792 23.75 30.4792H21.9365C21.4667 29.3186 20.3289 28.5 19 28.5C17.251 28.5 15.8333 29.9177 15.8333 31.6667C15.8333 33.4156 17.251 34.8333 19 34.8333C20.3289 34.8333 21.4667 34.0148 21.9365 32.8542H23.75C27.5497 32.8542 30.6912 30.0365 31.1991 26.3766C33.0632 25.8839 34.4375 24.1857 34.4375 22.1667V17.4167C34.4375 15.4032 33.0709 13.709 31.2146 13.2109C30.6107 7.66268 25.2165 3.5625 19 3.5625C12.7835 3.5625 7.38931 7.66268 6.78533 13.2109Z"
          fill="white"
        />
      </svg>
    ),
  },
  {
    img: '/landing-coop-3.png',
    title: 'Natychmiastowe działanie',
    svg: (
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.1665 25.2383C3.1665 26.55 4.22983 27.6133 5.5415 27.6133C6.85318 27.6133 7.9165 26.55 7.9165 25.2383V12.779C7.9165 11.4674 6.85318 10.404 5.5415 10.404C4.22983 10.404 3.1665 11.4674 3.1665 12.779V25.2383Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.104 13.6794V24.096C10.2208 24.4356 11.2983 25.0653 12.3762 25.6953C13.7311 26.4872 15.0867 27.2795 16.5216 27.4961C17.7352 27.6794 18.4834 27.6178 19.6226 27.5239C19.7323 27.5149 19.8458 27.5056 19.9636 27.4961C25.8156 27.0269 33.7316 21.1306 33.7316 21.1306C33.7316 21.1306 35.4527 20.221 34.5922 18.4025C33.7316 16.5839 32.0107 17.493 32.0107 17.493L24.7692 20.0438C25.2771 19.7995 25.9871 19.2489 25.9871 18.0808C25.9871 16.262 23.8225 16.4414 23.4056 16.5837C21.6847 17.1715 20.1835 17.1607 18.2425 16.5837C17.2879 16.3 16.6777 15.7592 16.0648 15.216C15.4667 14.6858 14.866 14.1534 13.9401 13.8556C12.4877 13.3885 10.4013 13.5243 9.104 13.6794Z"
          fill="white"
        />
      </svg>
    ),
  },
];

function Item(props: { img: string; title: string; svg: JSX.Element }) {
  return (
    <div className="flex flex-col items-center text-center border border-solid border-[#D9D9D9] rounded-[20px] bg-[#D9D9D9] overflow-hidden">
      <img
        alt={props.title}
        loading="lazy"
        className="max-h-[253px]"
        src={props.img}
      />
      <div className="flex flex-row gap-4 items-center py-4 text-start bg-white h-full w-full rounded-b-[20px]">
        <div className="h-[86px] w-[86px] min-h-[86px] min-w-[86px] bg-[#32A771] rounded-full flex flex-col justify-center items-center ml-4">
          {props.svg}
        </div>
        <div className="text-[16px] xl:text-[20px] font-bold text-[#161616] w-[60%]">
          {props.title}
        </div>
      </div>
    </div>
  );
}

export default function CooperationSection() {
  return (
    <section
      className={`flex flex-col gap-4 py-12 md:py-24 bg-[#F3F3F3] ${defaultStyles.paddingBig}`}
    >
      <div className="text-[35px] xl:text-[45px] text-black flex flex-col justify-center">
        Współpraca z nami - <span className="font-bold">dlaczego warto?</span>
      </div>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
        {items.map((item, index) => (
          <Item {...item} key={index} />
        ))}
      </div>
    </section>
  );
}
